import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';
import {Notification} from '../model/user/notification';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();


    constructor(http: HttpClient) {
        super(http);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    search(page, filter) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: data => {
                this.userDataSource.next(data as User);
                localStorage.setItem('profile', data['profile']);
                localStorage.setItem('country', data['country']);
                localStorage.setItem('isCeam', data['isCeam']);
                this.getUserLocal();
            }
        });
    }

    create(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.put(url, model);
    }

    updateUser(user: User) {
        this.userDataSource.next(user);
    }

    getUserLocal(): User {
        return this.userDataSource.value;
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    getNotification() {
        const url = `${this.BASE_URL}/user/notification`;
        this.http.get(url).subscribe({
            next: data => {
                this.notificationDataSource.next(data as Notification[]);
            }, error: err => {
            }
        });
    }

    updateNotification(id) {
        const url = `${this.BASE_URL}/user/notification/${id}`;
        return this.http.put(url, {});
    }

}
