<div class="flags justify-content-center">
    <!--<ng-container>
        <img class="flags_thumb" src="assets/images/country/{{handlerFlagImage(list)}}" [alt]="handlerFlagImage(list)" />
    </ng-container>-->
    <ng-container *ngIf="list.length > 0">
        <ng-container *ngFor="let s of list; let i = index">
            <span class="d-flex flex-column justify-content-center align-items-center" *ngIf="i < 3">
                <img class="flags_thumb" src="assets/images/country/{{handlerFlagImage(s)}}"  title="{{getCountryNameByKey(s)}}" [alt]="handlerFlagImage(s)" />
                <small>{{getCountryShort(s)}}</small>
            </span>
        </ng-container>
        <div class="flag_extended" *ngIf="list.length > 3">
            <ul>
                <li>
                    <div class="flag_extended_inside">
                        <div class="flag_extended_inside_flag d-flex">
                            <span class="d-flex flex-column justify-content-center align-items-center" *ngFor="let g of list">
                                <img class="flags_thumb"
                                     src="assets/images/country/{{handlerFlagImage(g)}}" title="{{getCountryNameByKey(g)}}"
                                     [alt]="handlerFlagImage(g)" />
                                <small>{{getCountryShort(g)}}</small>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <span class="p-0 m-0" *ngIf="list?.length > 3">+{{list?.length - 3}}</span>
    </ng-container>
</div>
