import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {EventBusService} from '../../../service/event-bus.service';

@Component({
    selector: 'app-country-flags',
    templateUrl: './country-flags.component.html',
    styleUrls: ['./country-flags.component.scss']
})
export class CountryFlagsComponent extends BaseComponent implements OnInit {

    @Input() list: any = [];
    @Input() listAdd: any = [];
    @Input() listAddGroup: any = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private eventbus: EventBusService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        // console.log('country flags init');
        /*console.log(this.list);
        console.log(this.listAdd);
        console.log(this.listAddGroup);*/
        if (this.isNullOrUndefined(this.list)) {
            this.list = [];
            this.listAdd = [];
            this.listAddGroup = [];
            return;
        } else if (this.list.length > 0 && this.listAdd.length > 0) {
            this.listAddGroup.forEach(x => {
                x.countryNotifications.forEach(c => this.list.push(c));
                x.countryAdditional.forEach(a => this.listAdd.push(a));
            });
            this.list = Object.assign([], this.list.concat(this.listAdd)).filter((y, index, self) => {
                return index === self.indexOf(y);
            });
        } else if (this.listAddGroup.length > 0) {
            this.listAddGroup.forEach(x => {
                x.countryNotifications.forEach(c => this.list.push(c));
                x.countryAdditional.forEach(a => this.listAdd.push(a));
            });
            this.list = Object.assign([], this.list.concat(this.listAdd)).filter((y, index, self) => {
                return index === self.indexOf(y);
            });
        }
    }

}
