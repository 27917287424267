<nav id="sidebar" class="d-flex flex-column">
    <div class="d-flex flex-column flex-grow-1">
        <ul class="list-unstyled components navbar-nav" id="accordionExample">

            <li class="header__mobile d-xl-none">
                <div class="header">
                    <div class="d-flex justify-content-around text-white">
                        <font class="header_font text-white">Idioma:</font>
                        <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                           href="javascript:void('');"
                           (click)="switchLanguage('pt')">PT</a>
                        <span class="separator">|</span>
                        <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                           href="javascript:void('');"
                           (click)="switchLanguage('es')">ES</a>
                    </div>
                    <div class="header__btn mobile_notification notification_trigger">
                        <app-notification></app-notification>
                    </div>
                </div>
            </li>


            <li class="nav-item nav-item-no-hover">
                <a href="javascript:void('Nota');" class="nav-link text-white sidebar_top"
                   (click)="actionCloseCollapse()">
                    <span class="ico"><svg-icon src=""></svg-icon></span>
                    <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      PA - Latam
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
                      <!--<img src="https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg"
                           class="profile_photo"/>-->
                  </span>
                </span>
                </a>
            </li>

            <div class="mx-4" *ngIf="btnNew">
                <a href="javascript: void(0);" (click)="router.navigate(['/register'])"
                   class="btn btn-warning btn-home-custom">
                      <span class="icon flex-grow-0 mh-100">
                        <svg-icon src="assets/svg/plus.svg" class="svg-plus--white--black"></svg-icon>
                      </span>
                    <span class="separator">|</span>
                    <span class="text primary_font font-weight-bold flex-grow-1 btn-mini-text ">{{'sidebar.novasolicitacao' | translate}}</span>
                </a>

            </div>
            <hr>
            <li class="nav-item">
                <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link text-white"
                   (click)="actionCloseCollapse()">
                    <span class="lbl">Dashboard</span>
                </a>
            </li>

            <li class="nav-item ">
                <a href="javascript:void('Nota');" [routerLink]="['/my-list']" class="nav-link text-white"
                   (click)="actionCloseCollapse()">
                    <span class="lbl">{{'sidebar.minhalista' | translate}}</span>
                </a>
            </li>


            <li class="nav-item" *ngIf="user.profile === 'administrator' ||  user.profile === 'consult'">
                <a href="javascript:void('User');" [routerLink]="['/user']" class="nav-link text-white"
                   (click)="actionCloseCollapse()">
                    <span class="lbl">{{'sidebar.user' | translate}}</span>
                </a>
            </li>

        </ul>
        <div class="logout d-xl-none">
            <a href="javascript:void('sair');" (click)="actionLogout()">
                {{'sair' | translate}}
            </a>
        </div>
    </div>


    <p class="text-white text-center">
       {{version}}
    </p>

</nav>
