import {RequestGroup} from './request-group';
import {RequestHystory} from './request-hystory';
import {RequestHistoryAudits, RequestUnifications} from './request-unifications';
import {User} from '../user/user';

export class Request {

    id: string;
    name: string;
    code: string;
    motive: string;
    channelDistribution: string;
    unitMeasure: string;
    description: string;
    recordHolder: string;
    ministryRecord: string;
    dispatchedBy: string;
    recordValidity: Date;
    manufacturer: string;
    dueDate: number;
    dueDateRevised: number;
    codeSap: string;
    descriptionFinish: string;
    country: string;
    status: string;
    step: number;
    type: string;
    bulk: boolean;
    ean: string;
    dum: string;
    unificationObservation: string;
    unifications: RequestUnifications[];
    createdAt: Date;
    updatedAt: Date;
    group: RequestGroup[];
    history: RequestHystory[];
    historyAudits: RequestHistoryAudits[];
    createdBy: User;
    countryNotification: string;
    countryNotifications: any[];
    countryAdditional: any[];
    reasonRequest: number;
    molecule: string;
    concentration: string;
    concentrationType: string;
    productBrand: string;
    productBrandType: string;
    commercialPresentation: string;
    stepReprove: number;
    area_400: string;
    countrySolicitationPmoInt: string;
    countryManufacturingPmoInt: string;


    constructor() {
        this.group = [];
        this.history = [];
        this.unifications = [];
        this.countryNotifications = [];
        this.countryAdditional = [];
    }
}
