import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {UserFilter} from '../../model/user/user-filter';
import {Dashboard} from '../../model/dashboard/dashboard';
import {UserResult} from '../../model/user/user-result';
import {Enum} from '../../model/enum';
import {EnumService} from '../../service/enum.service';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit {

    modelUser: User = new User();
    modelListCountry: Enum[] = [];
    modalFilter: UserFilter = new UserFilter();
    modalResult: UserResult = new UserResult();
    currentPage = 1;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public enumService: EnumService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.actionEnum();
        this.actionList();
    }

    actionList() {
        this.loading.show();
        this.userService.search(this.currentPage, this.modalFilter).subscribe({
            next: data => {
                this.modalResult = data as UserResult;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    detailUser(model) {
        this.modelUser = model;
        $('#modalGroup').modal('show');
    }

    actionEnum() {
        this.enumService.getEnum('country').subscribe({
            next: data => {
                this.modelListCountry = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome<br>'},
            {value: this.modelUser.email, text: 'E-mail<br>'},
            {value: this.modelUser.profile, text: 'Perfil<br>'},
            {value: this.modelUser.country, text: 'País<br>'}
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelUser.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionCreatedUser() {
        this.loading.show();
        this.modelUser.isCeam = this.handlerIsCeam(this.modelUser.country);
        this.userService.create(this.modelUser).subscribe({
            next: data => {
                this.modelUser = new User();
                $('#modalGroup').modal('hide');
                this.loading.hide();
                this.actionList();
                super.showMessage('Sucesso', 'Usuário cadastrado com sucesso.', 'success');
            }, error: err => super.onError(err)
        });
    }

    actionUpdateUser() {
        this.loading.show();
        this.modelUser.isCeam = this.handlerIsCeam(this.modelUser.country);
        this.userService.update(this.modelUser).subscribe({
            next: data => {
                this.modelUser = new User();
                $('#modalGroup').modal('hide');
                this.loading.hide();
                this.actionList();
                this.userService.getMe();
                super.showMessage('Sucesso', 'Usuário atualizado com sucesso.', 'success');
            }, error: err => super.onError(err)
        });
    }

    actionDelete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse usuário?', () => {
            this.userService.delete(id).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Usuário deletado com sucesso.', 'success');
                }, error: err => super.onError(err)
            });
        });
    }


    pageChanged(event) {
        this.currentPage = event;
        this.actionList();
    }

    actionNew() {
        this.modelUser = new User();
    }

    handlerIsCeam(country) {
        switch (country) {
            case 'Guatemala':
            case 'Panamá':
            case 'Belize':
            case 'Nicarágua':
            case 'Honduras':
            case 'República Dominicana':
            case 'Costa Rica':
                return true;
            default:
                return false;
        }
    }
}
